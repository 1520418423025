<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {};
  },
  created(){
    if(this.$router.path !== '/login'){
          this.$router.replace('/login')
      }
  }
}
</script>

<style>
  #app{
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
  }
</style>
