<template>
  <div class="Box">
    <div class="topBox">
      <img src="../assets/icon/长标志.png" id="videoLogo" />
      <span class="title">司迈医灵通</span>
      <span class="shortID">工作站ID：{{shortID}}</span>
    </div>
    <div class="container">
      <div class="mainVideoBox">
        <span class="copyRight"
          >@CopyRight[2021]珠海市司迈科技有限公司 version:1.0.4</span
        >
        <div class="appCover" v-if="isFold">
          <img src="../assets/icon/logoBackground.png" />
        </div>
        <div class="functionLayer" v-if="isPlay">
          <div class="functionBlock" @click="muteClick()">
            <img src="../assets/icon/语音.png" v-if="!isMute" />
            <img src="../assets/icon/语音关闭.png" v-if="isMute" />
            <span>{{ muteText }}</span>
          </div>
          <div class="functionBlock" @click="videoClick()">
            <img src="../assets/icon/摄像.png" v-if="!isVideo" />
            <img src="../assets/icon/关闭视频.png" v-if="isVideo" />
            <span>{{ videoText }}</span>
          </div>
          <el-dropdown placement="top">
            <!-- <i class="el-icon-sort"></i> -->
            <span class="dropDownLink">切换摄像头</span>
            <el-dropdown-menu slot="dropdown" class="dropDownMenu">
              <el-dropdown-item :key="item.deviceId" v-for="item in cameras"
                ><div @click="switchCamera(item.deviceId)">
                  {{ item.label }}
                </div></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div class="functionBlock">
            <el-switch
  v-model="value1"
  active-text="按月付费"
  inactive-text="按年付费">
</el-switch>
          </div> -->
          <div class="endButton">
            <el-button @click="stopPlay()" type="danger">结束</el-button>
          </div>
        </div>
        <button v-if="isMove" class="initScreen" @click="initScreenClick">
          还原屏幕
        </button>
        <div
          id="secVideoScreen"
          class="secVideoBox"
          v-if="remoteStreamSave.connection_ != null"
          @click="windowPlus"
          v-drag="{ set: initMethod }"
        >
          <div class="secVideo">
            <div
              v-html="remoteStream"
              :class="
                remoteStream ? 'distant-stream' : 'distant-stream-offline'
              "
            ></div>
          </div>
        </div>
        <div class="mainVideo">
          <img
            class="loadingPic"
            src="../assets/icon/标志.jpg"
            v-if="isPlayLoading"
          />
          <div id="local_stream" class="local-stream"></div>
        </div>
      </div>
      <!-- 聊天区域 -->
      <div class="comunicateBox">
        <div
          v-if="remoteStreamSave.connection_ == null"
          class="disConnectedCover"
        >
          <div>等待对方连接...</div>
          <el-button type="primary" :loading="canRecall" @click="rePushNumber"
            >重新呼叫</el-button
          >
          <div style="margin-top: 15px">
            <el-input placeholder="若对方未接听，可留言" v-model="leaveMsg">
              <el-button
                slot="append"
                icon="el-icon-s-comment"
                @click="sendLeaveMsg"
                >留言</el-button
              >
            </el-input>
          </div>
        </div>
        <div
          :class="[
            { userListBoxDisFold: !isFold },
            { userListBoxFold: isFold },
          ]"
        >
          <div class="listSearchBox">
            <el-button
              @click="getOnlineInfo()"
              class="refreshList"
              icon="el-icon-refresh"
              circle
            ></el-button>
            <div class="listSearchArea">
              <el-autocomplete
                class="inline-input"
                v-model="search"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              >
                <template slot-scope="{ item }">
                  <div class="searchList">
                    <div class="name">{{ item.realName }}</div>
                    <div class="addr">{{ item.nickName }}</div>
                  </div>
                </template>
              </el-autocomplete>
            </div>
          </div>
          <div class="userListBox">
            <span class="userToast" v-if="userList.length == 0"
              >当前没有用户在线</span
            >
            <div class="userList" :key="item.uid" v-for="item in userList">
              <div class="avatarBox" @click="userBoxClick(item)">
                <img
                  :src="item.avatarUrl"
                  :class="[
                    { offLineBox: item.country == undefined },
                    { onLineBox: item.country != undefined },
                  ]"
                />
              </div>
              <div class="userInfoBox" @click="userBoxClick(item)">
                <div>
                  <div class="realName">{{ item.realName }}</div>
                  <div class="nickName">{{ item.nickName }}</div>
                </div>
                <div>
                  <span class="loginTime">{{ item.loginTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="comunicateWindow">
          <div class="comuHeader">
            <div class="comuName">{{ userName }}</div>
          </div>
          <div class="comuMain" id="gundong">
            <div
              v-for="item in msgList"
              :key="item.createTime"
              :class="[
                { myMsgBox: item.fromId == roomInfo.stationId },
                { otherMsgBox: item.fromId != roomInfo.stationId },
              ]"
            >
              <img
                v-if="item.fromId != roomInfo.stationId"
                :src="avatarUrl"
                height="60px"
                width="60px"
              />
              <img
                v-if="item.fromId == roomInfo.stationId"
                src="../assets/icon/标志.jpg"
                height="60px"
                width="60px"
              />
              <div
                :class="[
                  { myChatBubbles: item.fromId == roomInfo.stationId },
                  { otherChatBubbles: item.fromId != roomInfo.stationId },
                ]"
              >
                <div>
                  <div class="chatText" v-if="item.type == 0">
                    {{ item.msg }}
                  </div>
                  <img
                    class="chatImage"
                    :src="item.msg"
                    v-if="picShow || item.type == 1"
                    height="200px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="comuFooter">
            <div class="comuFunctionArea">
              <!-- 图片上传 -->
              <el-upload
                action="https://yhxcx.scanmed-cn.com/api/v1/img/upload"
                :on-success="picUploadSuccess"
                :on-progress="picLoad"
                :on-error="picUploadError"
                :file-list="fileList"
                :show-file-list="false"
              >
                <div class="comuPic"><img src="../assets/icon/图片.png" /></div>
              </el-upload>
            </div>
            <div class="comuArea">
              <textarea
                @keydown="listenKeyCode($event)"
                v-model="msg"
                cols="20"
                rows="5"
                placeholder="请输入内容"
              ></textarea>
            </div>
            <div class="comuButtonArea">
              <button @click="send()">
                <img src="../assets/icon/飞机.png" />发 送
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <audio v-if="audio" loop="loop" autoplay="autoplay">
      <source src="../assets/bgm.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
import LibGenerateTestUserSig from "../../private/lib-generate-test-usersig.min";
import TRTC from "trtc-js-sdk";

export default {
  data() {
    return {
      //图片列表
      fileList: [],
      stationName: "admin", //工作站名 --可更改
      shortID:0,
      //创建沟通房间号参数
      roomInfo: {
        stationId: "",
        uid: 0,
        creatType: 0,
      },
      //沟通房间号
      roomId: "", //房间号--加入相同房间才能聊
      //聊天变量相关
      // path: "ws://101.200.160.17:8888/ws/chat",
      path: "wss://yhxcx.scanmed-cn.com/ws/chat",
      socket: "",
      msg: "",
      userName: "",
      avatarUrl: "",
      client2: "",
      client: "", //客户端服务
      remoteStream: "", //远方播放流ID
      localStream: "", //本地流
      remoteStreamSave: "", //远方播放流
      /////////////
      msgList: [],
      mediaLocalRecorder: "", //本地视频录制对象
      mediaRemoteRecorder: "", //远端视频录制对象
      canRecorderStart: true, //录制是否可以开始
      canRecorderStop: false, //录制是否可以结束
      isPlay: false, //是否开始推流
      isPlayLoading: false, //是否推流加载中
      muteText: "静音", //静音按钮文本
      videoText: "关闭镜头", //视频按钮文本
      recordText: "开启录制", //录制按钮文本
      startRecordClickFlag: false, //录制点击标志
      muteClickFlag: false, //静音点击标志
      isMute: false, //是否静音
      videoClickFlag: false, //视频点击标志
      isVideo: false, //是否打开视频
      isSilence: false,

      roomID: window.localStorage.getItem("roomID"),
      userList: [],
      onLineList: [],
      offLineList: [],

      isFold: true,
      search: "",
      canRecall: false,

      picShow: false,

      //屏幕录制
      shareStream: "",
      shareRecorder: "",
      shareRecorder2: "",
      shareRecorder3: "",
      //页面关闭前发送请求
      beforeUnloadTime: "",
      gapTime: "",
      timer: "",

      isPlus: false, //视频是否缩放
      isMove: false,

      //留言消息
      leaveMsg: "",

      isWindowShow: false,
      hangUp: false,
      audio: false,

      //摄像头列表
      cameras: null,
    };
  },
  created() {
    this.roomInfo.stationId = window.localStorage.getItem("stationId");
    this.stationName = window.localStorage.getItem("stationName");
    this.shortID = window.localStorage.getItem("shortID");
    
    this.getCameras();
    //获取离线留言未读消息
    this.getLeaveMsg();
    this.connectWs();
    this.clearRoom();
    this.getOfflineInfo(); //获取离线用户列表
    this.getOnlineInfo();
    clearInterval(this.timer);
    //轮询更新数据
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getOfflineInfo(); //获取离线用户列表
        this.getOnlineInfo(); //获取服务器信息
      }, 0);
    }, 5000);
  },
  mounted() {
    if (!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
      let error = "Your browser does NOT support the getDisplayMedia API.";
      throw new Error(error);
    }
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
    this.getOfflineInfo(); //获取离线用户列表
    this.getOnlineInfo();
  },
  destroyed() {
    clearInterval(this.timer);
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el, bindings) {
        let odiv = el; //获取当前元素
        odiv.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          document.onmousemove = (e) => {
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            odiv.style.left = left + "px";
            odiv.style.top = top + "px";
            bindings.value.set(true);
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  methods: {
    //获取摄像头列表
    getCameras() {
      TRTC.getCameras().then((devices) => {
        this.cameras = devices;
        console.log(this.cameras);
        devices.forEach((dev) => {
          console.log(
            "camera label: " + dev.label + " deviceId: " + dev.deviceId
          );
        });
      });
    },
    //切换摄像头
    switchCamera(e) {
      console.log(e);
      this.localStream.muteVideo();
      this.localStream.switchDevice("video", e).then(() => {
        console.log("switch camera success");
        this.localStream.unmuteVideo();
      });
    },
    //清空历史房间
    clearRoom() {
      let res = this.$http.post(
        "/api/v1/room/clear/" + this.roomInfo.stationId
      );
    },
    //获取离线消息
    async getLeaveMsg() {
      let cont = {
        curPage: 1,
        pageSize: 50,
        stationId: this.roomInfo.stationId,
      };
      const { data: res } = await this.$http.get(
        "/api/v1/offline/msg/station/unread?" +
          "stationId=" +
          this.roomInfo.stationId
      );
      if (res.data.records.length != 0) {
        //不做归类
        // for (var i = 0; i < res.data.records.length; i++) {
        //   this.$notify({
        //     title: res.data.records[i].uid + "的离线留言",
        //     message: res.data.records[i].msg,
        //     duration: 0,
        //     onclose(){

        //     }
        //   });
        // }
        //离线消息数组按uid归类
        var nameContainer = {};
        var msgTotal = [];
        var deleteArr = [];
        res.data.records.forEach((e) => {
          deleteArr.push(e.id);
        });
        res.data.records.forEach((item) => {
          nameContainer[item.uid] = nameContainer[item.uid] || [];
          nameContainer[item.uid].push(item);
        });
        msgTotal = Object.values(nameContainer);
        for (var i = 0; i < msgTotal.length; i++) {
          var msg = "";
          for (var j = 0; j < msgTotal[i].length; j++) {
            msg =
              msg +
              msgTotal[i][j].msg +
              " [" +
              msgTotal[i][j].createTime +
              "]" +
              "<br/>";
          }
          this.$notify({
            title: msgTotal[i][0].realName + "的离线留言",
            message: msg,
            dangerouslyUseHTMLString: true,
            duration: 0,
            position: "top-left",
            offset: 50,
            onClose: () => {
              if (deleteArr.length != 0) {
                this.$http.put("/api/v1/offline/msg/read", deleteArr);
                deleteArr = [];
              } else {
                return;
              }
            },
          });
        }
      }
    },
    //发送离线消息
    async sendLeaveMsg() {
      if (this.leaveMsg != "") {
        let cont = {
          msg: this.leaveMsg,
          type: 0,
          sendType: 0,
          stationId: this.roomInfo.stationId,
          uid: this.roomInfo.uid,
          createTime: Date.now(),
        };
        const { data: res } = await this.$http.post(
          "/api/v1/offline/msg/send",
          cont
        );
        if (res.code == 200) {
          this.leaveMsg = "";
          this.$message.success("留言成功！");
        } else {
          this.$message.error("留言失败！");
        }
      } else {
        this.$message.warning("留言消息不能为空！");
      }
    },

    //还原屏幕
    initScreenClick() {
      var ab = document.getElementById("secVideoScreen");
      ab.removeAttribute("style");
      this.isMove = false;
    },
    initMethod(x) {
      this.isMove = x;
    },
    //监听应用关闭
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime();
      //退出登录
      this.logout();
      ///删除房间号
      this.deleteRoom();
      clearInterval(this.timer);
    },
    unloadHandler(e) {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime;
      //判断是窗口关闭还是刷新  要发送同步请求
      if (this.gapTime <= 50) {
        //退出登录
        this.logout();
        ///删除房间号
        this.deleteRoom();
      } else {
        this.deleteRoom();
        this.logout();
      }
    },
    //退出登录
    logout() {
      let res = this.$http.post("/api/v1/admin/station/logout");
    },
    //删除房间号
    deleteRoom() {
      let res = this.$http.post("/api/v1/room/close/" + this.roomId);
    },
    //点击用户列表
    userBoxClick(e) {
      //点击离线头像
      if (e.country == undefined) {
        this.$prompt("您可以发送留言：", "对方不在线", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPlaceholder: "在此键入留言信息",
          inputPattern: /\S/,
          inputErrorMessage: "留言不能为空",
          confirmButtonText: "留言",
        })
          .then(async ({ value }) => {
            let cont = {
              msg: value,
              type: 0,
              sendType: 0,
              stationId: this.roomInfo.stationId,
              uid: e.uid,
              createTime: Date.now(),
            };
            const { data: res } = await this.$http.post(
              "/api/v1/offline/msg/send",
              cont
            );
            if (res.code == 200) {
              this.$message.success("留言成功！");
            } else {
              this.$message.error("留言失败！");
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消留言",
            });
          });
      } else {
        this.roomInfo.uid = e.uid;
        //清空上次记录
        this.shareRecorder = "";
        (this.shareRecorder2 = ""), console.log("/");
        this.$confirm("确认向【" + e.realName + "】发起实时沟通？", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          //收起用户列表
          this.isFold = false;
          if (this.roomId != "") {
            //先删除房间号
            const { data: deleteRes } = await this.$http.post(
              "/api/v1/room/close/" + this.roomId
            );
          }
          //创建房间号
          const { data: createRes } = await this.$http.post(
            "/api/v1/room/create",
            this.roomInfo
          );
          //如果房间创建成功
          if (createRes.code == 200) {
            this.roomId = createRes.data;
            //打印房间号
            // console.log("房间号是：" + this.roomId);
            //相应用户信息赋值给页面
            this.userName = e.realName;
            this.avatarUrl = e.avatarUrl;
            //启动本地视频
            this.startPlay();
          } else {
            //如果房间号创建失败
            this.$notify({
              title: "错误",
              message: "创建房间失败,请与后台管理人员联系",
              type: "error",
            });
            this.isFold = true;
          }
        });
      }
    },
    //重新呼叫用户
    rePushNumber() {
      this.pushRoomNumber();
      this.canRecall = true;
      var that = this;
      setTimeout(function () {
        that.canRecall = false;
      }, 10000);
    },
    // 图片上传成功
    picUploadSuccess(res) {
      this.picShow = false;
      let cont = {
        msg: res.msg,
        type: 1,
        roomId: this.roomId,
        fromId: this.roomInfo.stationId,
        toId: this.roomInfo.uid,
        createTime: Date.now(),
      };
      if (res.code == 200) {
        // console.log("发送消息成功！" + JSON.stringify(cont));
        this.socket.send(JSON.stringify(cont));
        this.msgList.push(cont);
        // console.log(this.msgList);
        this.msg = "";
        this.$nextTick(() => {
          let msg = document.getElementById("gundong");
          msg.scrollTop = msg.scrollHeight;
        });
      }
    },
    //启动屏幕录制
    async startRec() {
      this.shareStream = TRTC.createStream({ audio: true, screen: true });
      // this.shareStream2 = TRTC.createStream({
      //   screenAudio: true,
      //   screen: true,
      // });
      try {
        await this.shareStream.initialize();
        // await this.shareStream2.initialize();
        this.pushRoomNumber();
      } catch (e) {
        //推送房间号
        this.pushRoomNumber();
        switch (e.name) {
          case "NotReadableError":
            // 提醒用户确保系统允许当前浏览器获取屏幕内容
            this.$message.error("缺少权限,本次聊天将不会记录屏幕内容");
            return;
          case "NotAllowedError":
            if (e.message === "Permission denied by system") {
              // 提醒用户确保系统允许当前浏览器获取屏幕内容
              this.$message.error("缺少权限,本次聊天将不会记录屏幕内容");
            } else {
              // 用户拒绝/取消屏幕分享
              this.$message.error("缺少权限,本次聊天将不会记录屏幕内容");
            }
            return;
          default:
            // 初始化屏幕分享流时遇到了未知错误，提醒用户重试
            this.$message.error("缺少权限,本次聊天将不会记录屏幕内容");
            return;
        }
      }
      this.shareRecorder = new MediaRecorder(this.shareStream.mediaStream_, {
        audioBitsPerSecond: 2500000, // 音频码率
        videoBitsPerSecond: 2500000, // 视频码率
        mimeType: "video/webm;codecs=h264", // 编码格式
      });
      this.shareRecorder.start();
      console.log("开始采集视频");

      // this.shareRecorder2 = new MediaRecorder(this.shareStream2.mediaStream_, {
      //   audioBitsPerSecond: 1500000, // 音频码率
      //   videoBitsPerSecond: 1500000, // 视频码率
      //   mimeType: "video/webm;codecs=h264", // 编码格式
      // });
      // this.shareRecorder2.start();
      // console.log("开始采集视频");
    },
    //停止屏幕录制
    stopRec() {
      this.shareRecorder.stop();
      this.shareStream.close();
      // this.shareRecorder2.stop();
      // this.shareStream2.close();
      this.$notify({
        title: "已结束聊天",
        message: "请等待聊天过程文件下载请求，这可能需要一段时间...",
        type: "success",
        duration: 4500,
      });
      var that = this;
      this.shareRecorder.ondataavailable = function (e) {
        // 下载视频
        var blob = new Blob([e.data], { type: "video/webm" });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "（录屏文件）【" + that.userName + `】.webm`;
        a.click();
      };
      // this.shareRecorder2.ondataavailable = function (e) {
      //   // 下载视频
      //   var blob1 = new Blob([e.data], { type: "video/webm" });
      //   let a1 = document.createElement("a");
      //   a1.href = URL.createObjectURL(blob1);
      //   a1.download = "与【" + that.userName + `】的视频(对方音频).webm`;
      //   a1.click();
      // };
    },
    picUploadError() {
      this.$message.error("发送失败：只支持jpg/png格式，且不超过500kb");
    },
    //图片上传中
    picLoad() {
      this.picShow = true;
    },
    //websocket相关
    connectWs() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(
          this.path + "?userId=" + this.roomInfo.stationId
        );
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        this.socket.onclose = this.close;
      }
    },
    open: function () {
      // console.log("socket连接成功");
    },
    error: function () {
      // console.log("连接错误");
    },
    getMessage: function (msg) {
      // console.log("收到消息：" + msg.data);
      // if (msg.data == "对方并未在线") {
      //   setTimeout(this.pushRoomNumber, 3000);
      // }
      let msgData = JSON.parse(msg.data);
      // console.log(msgData);
      if (msgData.type == -3 && this.isWindowShow == true) {
        this.hangUp = true;
      }
      if (msgData.type == -1 && this.isFold) {
        for (var i = 0; i < this.userList.length; i++) {
          if (msgData.fromId == this.userList[i].uid) {
            // console.log(this.userList[i]);
            let user = this.userList[i];
            this.roomId = msgData.roomId;
            this.isWindowShow = true;
            this.audio = true;
            this.$confirm(
              "收到来自【" + msgData.msg + "】的视频请求,是否接受？",
              "通知",
              {
                confirmButtonText: "接受",
                cancelButtonText: "拒绝",
                type: "info",
              }
            )
              .then(() => {
                this.audio = false;
                if (this.hangUp == true) {
                  this.$message.warning("对方已取消");
                  this.hangUp = false;
                  this.isWindowShow = false;
                  this.clearRoom();
                } else {
                  this.roomInfo.uid = msgData.fromId;
                  this.userName = user.nickName;
                  this.avatarUrl = user.avatarUrl;
                  this.isFold = false;
                  this.isWindowShow = false;
                  this.hangUp = false;
                  //启动本地视频
                  this.startPlay();
                }
              })
              .catch(() => {
                this.audio = false;
                let res = this.$http.post(
                  "/api/v1/room/close/" + msgData.roomId
                );
                this.isWindowShow = false;
                this.hangUp = false;
                this.clearRoom();
                let refuseMsg = {
                  msg: "refuse",
                  type: -2,
                  roomId: this.roomId,
                  fromId: this.roomInfo.stationId,
                  toId: msgData.fromId,
                  createTime: Date.now(),
                };
                this.socket.send(JSON.stringify(refuseMsg));
              });
          }
        }
      }
      /////////////////////////////////
      if (msgData.type == -2 && this.isPlayLoading == false) {
        this.leaveRoom(this.client);
        this.deleteRoom();
        this.isFold = true;
        this.isMove = false;
        if (this.remoteStreamSave == "") {
          if (this.shareRecorder != "" || this.shareRecorder2 != "") {
            this.stopRec();
          }
        }
        this.$alert("对方已拒绝", "提示", {
          confirmButtonText: "确定",
        });
      }
      ////////////////////////////////
      if (msgData.type == 1 || msgData.type == 0) {
        let cont = {
          msg: msgData.msg,
          type: msgData.type,
          roomId: msgData.roomId,
          fromId: msgData.fromId,
          toId: msgData.toId,
          createTime: msgData.createTime,
        };
        this.msgList.push(cont);
        this.$nextTick(() => {
          let msg = document.getElementById("gundong");
          msg.scrollTop = msg.scrollHeight;
        });
        // console.log(this.msgList);
      }
    },
    send: function () {
      let cont = {
        msg: this.msg,
        type: 0,
        roomId: this.roomId,
        fromId: this.roomInfo.stationId,
        toId: this.roomInfo.uid,
        createTime: Date.now(),
      };
      if (this.msg != "") {
        // console.log("发送消息成功！" + JSON.stringify(cont));
        this.socket.send(JSON.stringify(cont));
        this.msgList.push(cont);
        // console.log(this.msgList);
        this.msg = "";
        this.$nextTick(() => {
          let msg = document.getElementById("gundong");
          msg.scrollTop = msg.scrollHeight;
        });
      }
    },
    close: function () {
      // console.log("socket已经关闭");
      this.connectWs();
    },
    //监听键盘回车发送事件
    listenKeyCode(event) {
      if (event.keyCode == 13) {
        if (this.msg != "") {
          this.send();
        }
        event.preventDefault();
        return false;
      }
    },
    //存储聊天记录
    ExportData() {
      if (this.msgList.length != 0) {
        //定义文件内容，类型必须为Blob 否则createObjectURL会报错
        let content = new Blob([JSON.stringify(this.msgList)]);
        //生成url对象
        let urlObject = window.URL || window.webkitURL || window;
        let url = urlObject.createObjectURL(content);
        //生成<a></a>DOM元素
        let el = document.createElement("a");
        //链接赋值
        el.href = url;
        el.download = "聊天记录.txt";
        //必须点击否则不会下载
        el.click();
        //移除链接释放资源
        urlObject.revokeObjectURL(url);
      }
    },
    //用户列表搜索
    querySearch(queryString, cb) {
      var userList = this.userList;
      var results = queryString
        ? userList.filter(this.createFilter(queryString))
        : userList;
      // 调用 callback 返回建议列表的数据
      // console.log(results);
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.realName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(e) {
      // console.log(e);
      this.userBoxClick(e);
    },
    //点击聊天窗口放大
    windowPlus() {
      this.isPlus = ~this.isPlus;
    },
    //获取用户登录信息
    async getOnlineInfo() {
      // const { data: res } = await this.$http.get("/api/v1/wx/user/online");
      const { data: res } = await this.$http.get("/api/v1/wx/user/online/"+this.shortID);
      this.onLineList = res.data;
      this.userList = this.onLineList.concat(this.offLineList);
      console.log(this.offLineList)
      let hash = {};
      this.userList = this.userList.reduce((item, next) => {
        if (!hash[next.uid]) {
          hash[next.uid] = true;
          item.push(next);
        }
        return item;
      }, []);
    },
    //获取离线用户登录信息
    async getOfflineInfo() {
      const { data: res } = await this.$http.get(
        "/api/v1/video/station?" + "stationId=" + this.roomInfo.stationId
      );
      this.offLineList = res.data.records;
    },
    //点击静音按键
    muteClick() {
      this.muteClickFlag = !this.muteClickFlag;
      if (this.muteClickFlag) {
        this.localStream.muteAudio();
        this.isMute = true;
        this.muteText = "已静音";
      } else {
        this.localStream.unmuteAudio();
        this.isMute = false;
        this.muteText = "静音";
      }
    },
    //点击视频按键
    videoClick() {
      this.videoClickFlag = !this.videoClickFlag;
      if (this.videoClickFlag) {
        this.localStream.muteVideo();
        this.isVideo = true;
        this.videoText = "镜头已关";
      } else {
        this.localStream.unmuteVideo();
        this.isVideo = false;
        this.videoText = "关闭镜头";
      }
    },
    //推送房间号消息
    pushRoomNumber() {
      let roomNumberMsg = {
        msg: "工作站进入聊天房间",
        type: -1,
        roomId: this.roomId,
        fromId: this.roomInfo.stationId,
        toId: this.roomInfo.uid,
        createTime: Date.now(),
      };
      // console.log("推送房间号信息成功！" + JSON.stringify(roomNumberMsg));
      this.socket.send(JSON.stringify(roomNumberMsg));
      this.msg = "";
    },
    //开房
    startPlay() {
      this.isMove = false;
      if (this.localStream) {
        this.$message.warning("请先结束当前视频");
      } else {
        // this.createClient(this.stationName);
        this.createClient(this.roomInfo.stationId);
        this.isPlayLoading = true;
        this.isFold = false;
        this.msgList = [];
        this.$notify({
          title: "沟通开始",
          message: "请授予视频与音频权限",
          type: "success",
        });
        this.startRec();
      }
    },
    //退房
    stopPlay() {
      // this.roomId = "未创建"; //退房销毁房间号
      // var that = this;
      this.$confirm("是否结束与【" + this.userName + "】的沟通？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.remoteStreamSave == "") {
            if (this.shareRecorder != "" || this.shareRecorder2 != "") {
              this.stopRec();
            }
          }
          this.leaveRoom(this.client);
          this.deleteRoom();
          this.isMute = false;
          this.muteText = "静音";
          this.isVideo = false;
          this.videoText = "关闭镜头";
          this.isFold = true;
          this.isMove = false;
          let hangupMsg = {
            msg: "hangup",
            type: -3,
            roomId: this.roomId,
            fromId: this.roomInfo.stationId,
            toId: this.roomInfo.uid,
            createTime: Date.now(),
          };
          this.socket.send(JSON.stringify(hangupMsg));
        })
        .catch((res) => {
          // console.log(res);
        });
    },
    //创建链接
    createClient(userId) {
      //获取签名
      const config = this.genTestUserSig(userId);
      const sdkAppId = config.sdkAppId;
      const userSig = config.userSig;
      this.client = TRTC.createClient({
        mode: "videoCall",
        // useStringRoomId:true,
        sdkAppId,
        userId,
        userSig,
      });
      //注册远程监听，要放在加入房间前--这里用了发布订阅模式
      this.subscribeStream(this.client);
      //初始化后才能加入房间
      this.joinRoom(this.client, this.roomId);
      // console.log("加入的房间Id是：" + this.roomId);
    },
    //加入房间
    joinRoom(client, roomId) {
      client
        .join({ roomId })
        .catch((error) => {
          console.error("进房失败 " + error);
        })
        .then(() => {
          console.log("进房成功");
          //创建本地流
          this.createStream(this.userId);
          //播放远端流
          this.playStream(this.client);
        });
    },

    //创建本地音视频流
    createStream(userId) {
      const localStream = TRTC.createStream({
        userId,
        audio: true,
        video: true,
      });
      localStream.setVideoProfile('1080p');
      this.localStream = localStream;

      localStream
        .initialize()
        .catch((error) => {
          console.error("初始化本地流失败 " + error);
        })
        .then(() => {
          console.log("初始化本地流成功");
          // 创建好后才能播放 本地流播放 local_stream 是div的id
          localStream.play("local_stream");
          this.isPlay = true;
          this.isPlayLoading = false;
          this.canRecorderStart = true;
          this.canRecorderStop = false;
          this.shareRecorder3 = new MediaRecorder(
            this.localStream.mediaStream_,
            {
              audioBitsPerSecond: 1500000, // 音频码率
              videoBitsPerSecond: 1500000, // 视频码率
              mimeType: "video/webm;codecs=h264", // 编码格式
            }
          );
          this.shareRecorder3.start();
          //创建好后才能发布
          this.publishStream(localStream, this.client);
        });
    },

    //发布本地音视频流
    publishStream(localStream, client) {
      client
        .publish(localStream)
        .catch((error) => {
          console.error("本地流发布失败 " + error);
        })
        .then(() => {
          console.log("本地流发布成功");
        });
    },

    //订阅远端流--加入房间之前
    subscribeStream(client) {
      client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        console.log("远端流增加: " + remoteStream.getId());
        //订阅远端流
        client.subscribe(remoteStream);
      });
    },

    //播放远端流
    playStream(client) {
      client.on("stream-subscribed", (event) => {
        const remoteStream = event.stream;
        console.log(remoteStream);
        console.log("远端流订阅成功：" + remoteStream.getId());
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
        this.remoteStreamSave = remoteStream;
        this.remoteStream = `<view id="${
          "remote_stream-" + remoteStream.getId()
        }"  ></view>`;
        this.shareRecorder2 = new MediaRecorder(
          this.remoteStreamSave.mediaStream_,
          {
            audioBitsPerSecond: 1500000, // 音频码率
            videoBitsPerSecond: 1500000, // 视频码率
            mimeType: "video/webm;codecs=h264", // 编码格式
          }
        );
        this.shareRecorder2.start();
        //做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
        this.$nextTick(() => {
          //播放
          remoteStream.play("remote_stream-" + remoteStream.getId());
          //远端流刷新到界面上时
        });
        client.on("stream-removed", (event) => {
          console.log("远端流移除");
          this.deleteRoom();
          this.clearRoom();
          this.isMute = false;
          this.muteText = "静音";
          this.isVideo = false;
          this.videoText = "关闭镜头";
          //重新展开用户列表
          this.isFold = true;
          this.isMove = false;
          var that = this;
          try {
            that.shareRecorder2.ondataavailable = function (e) {
              // 下载视频
              var blob1 = new Blob([e.data], { type: "video/webm" });
              let a1 = document.createElement("a");
              a1.href = URL.createObjectURL(blob1);
              a1.download = "（对方视频）【" + that.userName + `】.webm`;
              a1.click();
              that.shareRecorder2.stop();
            };
          } catch (e) {
            console.log("下载出错" + e);
          }
          if (this.remoteStreamSave != "" && this.isFold == true) {
            if (this.shareRecorder != "") {
              try {
                this.stopRec();
              } catch (e) {
                console.log("下载出错");
              }
            }
            this.ExportData();
          }
          this.leaveRoom(this.client);
          this.remoteStreamSave = "";
        });
      });
    },

    //退出音视频
    leaveRoom(client) {
      client
        .leave()
        .then(() => {
          console.log("退房成功");
          // 停止本地流，关闭本地流内部的音视频播放器
          var that = this;

          this.shareRecorder3.ondataavailable = function (e) {
            // 下载视频
            var blob2 = new Blob([e.data], { type: "video/webm" });
            let a2 = document.createElement("a");
            a2.href = URL.createObjectURL(blob2);
            a2.download = "（本地视频）【" + that.userName + `】.webm`;
            a2.click();
            
          };
          try{
            this.shareRecorder3.stop();
          }catch(e){
            console.log(e);
          }
          this.localStream.stop();
          // 关闭本地流，释放摄像头和麦克风访问权限
          this.localStream.close();
          this.localStream = null;
          this.client = null;
          this.isPlay = false;
          this.isRecorderable = false;
          this.canRecorderStart = true;
          this.canRecorderStop = false;
          // 退房成功，可再次调用client.join重新进房开启新的通话。
        })
        .catch((error) => {
          console.error("退房失败 " + error);
          // 错误不可恢复，需要刷新页面。
        });
    },

    //获取用户签名--前端测试用
    genTestUserSig(userID) {
      /**
       * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
       *
       * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
       * 它是腾讯云用于区分客户的唯一标识。
       */
      const SDKAPPID = 1400494208;
      /**
       * 签名过期时间，建议不要设置的过短
       * <p>
       * 时间单位：秒
       * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
       */
      const EXPIRETIME = 604800;
      /**
       * 计算签名用的加密密钥，获取步骤如下：
       *
       * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
       * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
       * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
       *
       * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
       * 文档：https://cloud.tencent.com/document/product/647/17275#Server
       */
      const SECRETKEY =
        "5eaa22c6fc490f7c00396dd157c3dfc0b9e8fb9b4939a9734adc2dfcbd2436d8";

      // a soft reminder to guide developer to configure sdkAppId/secretKey
      if (SDKAPPID === "" || SECRETKEY === "") {
        alert(
          "请先配置好您的账号信息： SDKAPPID 及 SECRETKEY " +
            "\r\n\r\nPlease configure your SDKAPPID/SECRETKEY in js/debug/GenerateTestUserSig.js"
        );
      }
      const generator = new LibGenerateTestUserSig(
        SDKAPPID,
        SECRETKEY,
        EXPIRETIME
      );
      const userSig = generator.genTestUserSig(userID);
      return {
        sdkAppId: SDKAPPID,
        userSig: userSig,
      };
    },
  },
};
</script>

<style>
.el-main {
  background-color: #4f4f4f;
}
.Box {
  width: 100%;
  height: 100%;
  background-color: #4f4f4f;
}

.topBox {
  height: 5%;
  background-color: #fff;
  position: relative;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  overflow: hidden;
}
.topBox .title {
  float: left;
  color: #707d83;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
  
}
.topBox #videoLogo {
  height: 100%;
  float: left;
}
.topBox .shortID{
  position: absolute;
  right: 20px;
  transform: translate(0, 50%);
  font-weight: bold;
  color: #e26a13;
}

.container {
  height: 95%;
  width: 100%;
  background-color: #eee;
  display: flex;
}

.appCover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101;
  background-color: #000;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appCover img {
  opacity: 0.4;
}

.mainVideoBox {
  height: 100%;
  width: 70%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-right: 2px #707d83 solid;
}
.copyRight {
  color: #9c9c9c;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  z-index: 9999;
}
.functionLayer {
  height: 80px;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  z-index: 99;
  background-color: #000;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: functionLayer 1s forwards;
}
@keyframes functionLayer {
  from {
    bottom: -80px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 0.7;
  }
}
.functionBlock {
  cursor: pointer;
  height: 80px;
  width: 80px;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.functionBlock span {
  font-size: 8px;
  position: absolute;
  bottom: 2px;
}
.functionBlock:hover {
  background-color: #1c1c1c;
}
.endButton {
  position: absolute;
  right: 14px;
}
.mainVideo {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingPic {
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 9999;
  border-radius: 50%;
  animation: loading 1s infinite linear;
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.local-stream {
  height: 39.375vw;
  width: 70vw;
  transform: rotateY(180deg);
  background-color: #313131;
}
.initScreen {
  position: absolute;
  z-index: 100;
  bottom: 100px;
  right: 50%;
  transform: translate(50%, 0);
  height: 40px;
  width: 100px;
  background-color: #1c1c1c;
  border: none;
  color: #e26a13;
  opacity: 0.6;
  cursor: pointer;
}
.initScreen:hover {
  opacity: 0.8;
}
.secVideoBox {
  height: 32vh;
  width: 18vh;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  transform: translate(0, 0);
  overflow: hidden;
  animation: secVideoBoxSubAnim 0.8s forwards;
}
.secVideoBox:hover {
  opacity: 0.5;
}
@keyframes secVideoBoxSubAnim {
  from {
    height: 64vh;
    width: 32vh;
    right: 50%;
    transform: translate(50%, 0);
  }
  to {
    height: 32vh;
    width: 18vh;
    right: 0;
    transform: translate(0, 0);
  }
}
.secVideoBoxPlus {
  height: 80%;
  width: 50%;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  overflow: hidden;
  animation: secVideoBoxPlusAnim 0.8s forwards;
}
.secVideoBoxPlus:hover {
  opacity: 0.5;
}
@keyframes secVideoBoxPlusAnim {
  from {
    height: 40%;
    width: 25%;
    right: 0;
    transform: translate(0, 0);
  }
  to {
    height: 90%;
    width: 50%;
    right: 50%;
    transform: translate(50%, 0);
  }
}
.secVideo {
  height: 100%;
  width: 100%;
}
.distant-stream {
  height: 100%;
  width: 100%;
  background-color: #7b7b7b;
}
.distant-stream-offline {
  height: 100%;
  width: 100%;
  background-color: #7b7b7b;
}

.comunicateBox {
  height: 100%;
  width: 30%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.disConnectedCover {
  height: 100%;
  width: 100%;
  background-color: #eee;
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #4f4f4f;
  background-image: url(../assets/icon/logoBackground.png);
  background-repeat: no-repeat;
  background-position: center;
}
/* 用户列表区域 */
.userListBoxDisFold {
  height: 100%;
  width: 100%;
  background-color: #eee;
  position: absolute;
  /* border-radius: 30px 0 0 30px; */
  z-index: 99;
  right: 0;
  box-shadow: -3px 0 10px #9c9c9c;
  animation: listFoldAnim 0.7s forwards;
}
.userListBoxFold {
  height: 100%;
  width: 100%;
  background-color: #eee;
  position: absolute;
  /* border-radius: 30px 0 0 30px; */
  z-index: 99;
  right: -500px;
  box-shadow: -3px 0 10px #9c9c9c;
  animation: listDisFoldAnim 0.7s forwards;
}
@keyframes listFoldAnim {
  from {
    right: 0;
  }
  to {
    right: -1000px;
  }
}
@keyframes listDisFoldAnim {
  from {
    right: -1000px;
  }
  to {
    right: 0;
  }
}
/* 在线头像 */
.onLineBox {
  height: 80px;
  width: 80px;
}
/* 离线头像 */
.offLineBox {
  height: 80px;
  width: 80px;
  filter: grayscale(100%);
}
.listSearchBox {
  height: 10%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.refreshList {
  position: absolute;
  left: 20px;
}
.searchList {
  display: flex;
  justify-content: space-between;
}
.listSearchArea {
  width: 100%;
  display: flex;
  justify-content: center;
}
.userListBox {
  height: 90%;
  width: 100%;
  overflow: auto;
}
.userToast {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  color: #828282;
}
.userList {
  height: 100px;
  width: 100%;
  background-color: #eee;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
}
.userList:hover {
  background-color: #e8e8e8;
}
.userList .avatarBox {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userList .userInfoBox {
  height: 80px;
  width: 390px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.realName {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.nickName {
  font-size: 16px;
  color: #1c1c1c;
}
.loginTime {
  font-size: 14px;
  color: #696969;
  float: right;
  margin-right: 20px;
}
/* 聊天区域 */
.comunicateWindow {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px #9c9c9c;
  box-shadow: inset;
}
.comuHeader {
  height: 6%;
  width: 100%;
  background: linear-gradient(to right, #e46a15, #ff9a00);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  overflow: hidden;
}
.comuMain {
  height: 64%;
  width: 100%;
  background-color: #f5f5f5;
  background-image: url(../assets/icon/logoBackground.png);
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
}
.myMsgBox {
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.otherMsgBox {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.otherChatBubbles {
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 0 10px;
  font-size: 18px;
  background-color: #fff;
}
.myChatBubbles {
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 0 10px;
  font-size: 18px;
  background-color: #ffa500;
}
.chatImage {
  display: block;
  margin: 10px 0;
  /* background-image: url("../assets/icon/loading.gif"); */
  background-repeat: no-repeat;
  background-position: center;
}

.chatText {
  padding: 10px 0;
}
.comuFooter {
  height: 30%;
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
}
.comuFunctionArea {
  height: 20%;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 5px #e8e8e8;
  z-index: 9;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.comuFunctionArea .comuPic {
  height: 100%;
  margin-left: 10px;
  cursor: pointer;
  background-color: #fff;
}
.comuFunctionArea .comuPic:hover {
  background-color: #eee;
}

.comuArea {
  height: 60%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
.comuArea textarea {
  height: 90%;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  color: #1c1c1c;
  border: none;
  resize: none;
}
textarea::-webkit-input-placeholder {
  color: #cfcfcf;
  font-size: 24px;
}
.comuArea textarea:focus {
  outline: none;
}
.comuButtonArea {
  height: 20%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
}
.comuButtonArea button {
  background-image: linear-gradient(to right, #e46a15, #ff9a00);
  color: #fff;
  border: none;
  height: 80%;
  width: 100px;
  margin-right: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
}
.comuButtonArea button:focus {
  outline: 0;
}
.comuButtonArea button:hover {
  opacity: 0.8;
}
.comuButtonArea button:active {
  background-image: linear-gradient(to right, #cd6600, #ff7f00);
}

/*修改滚动条样式*/
div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /**/
}
div::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
div::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover {
  background: #333;
}
div::-webkit-scrollbar-corner {
  background: #179a16;
}
.dropDownLink {
  cursor: pointer;
  color: #bfbfbf;
}
</style>
