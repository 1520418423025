<template>
  <div class="dashBackground">
    <div class="dashTopBox">
      <div class="dashTitle">
        <span class="dashTitle1">SiMai</span><span class="dashTitle2">-DashBoard</span>
      </div>
      <div class="adminInfoBox">
        <el-dropdown class="adminInfo" @command="editPassword">
          <span class="adminInfo">
            <i class="el-icon-s-custom"></i>{{ adminName
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="dashStatsBox">
      <div class="dashListTitle">房间列表</div>
      <div class="roomListBox">
        <span class="roomToast" v-if="roomData.length==0">当前没有活跃的房间</span>
        <div class="roomListBanner">
          <el-carousel :interval="4000" type="card" height="350px">
          <el-carousel-item v-for="item in roomData" :key="item.roomId">
            <div class="roomInfoBox">
              <div><i class="el-icon-s-home roomIcon"></i></div>
              <div class="roomText">房间号</div>
              <div class="roomNumber">{{item.roomId}}</div>
              <div class="roomText">工作站Id</div>
              <div class="roomNumber">{{item.stationId}}</div>
              <div class="roomText">用户uid</div>
              <div class="roomNumber">{{item.uid}}</div>
              <div class="roomText">创建时间</div>
              <div class="roomNumber">{{item.createTime}}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
        </div>
      </div>
      <div class="dashStats">
        <div class="statsBox">
          <div class="stats">
            <div class="statsTitle">工作站数量</div>
            <div class="statsNumber">{{ workStationData.length }}</div>
          </div>
          <div class="stats">
            <div class="statsTitle">用户数量</div>
            <div class="statsNumber">{{ userData.length }}</div>
          </div>
          <div class="stats">
            <div class="statsTitle">房间数量</div>
            <div class="statsNumber">{{roomData.length}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务器状态 -->
    <div class="dashServerBox">
      <div class="dashServer">
        <div class="dashListTitle">服务器压力</div>
        <div class="dashServerCharts" ref="server"></div>
      </div>
    </div>
    <!-- 用户列表 -->
    <div class="dashUserBox">
      <div class="dashUserList">
        <div class="dashListTitle">
          用户列表<i class="el-icon-refresh-left" @click="getUserList()"></i>
        </div>
        <el-table
          :data="
            userData.filter(
              (data) =>
                !searchUser ||
                data.realName.toLowerCase().includes(searchUser.toLowerCase())
            )
          "
          style="width: 100%"
          height="92%"
          stripe
          :row-style="{ height: '80px' }"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="真实姓名:">
                  <span>{{ props.row.realName }}</span>
                </el-form-item>
                <el-form-item label="微信名:">
                  <span>{{ props.row.nickName }}</span>
                </el-form-item>
                <el-form-item label="uid:">
                  <span>{{ props.row.uid }}</span>
                </el-form-item>
                <el-form-item label="上次登录:">
                  <span>{{ props.row.loginTime }}</span>
                </el-form-item>
                <el-form-item label="省份:">
                  <span>{{ props.row.province }}</span>
                </el-form-item>
                <el-form-item label="城市:">
                  <span>{{ props.row.city }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column width="130" prop="avatarUrl" align="left">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchUser" size="medium" placeholder="搜索" />
            </template>
            <template slot-scope="scope">
              <img :src="scope.row.avatarUrl" width="50" height="50" />
            </template>
          </el-table-column>
          <el-table-column width="80" label="用户姓名" prop="realName">
          </el-table-column>
          <el-table-column  label="上次登录" prop="loginTime">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 工作站列表 -->
    <div class="dashWorkStationBox">
      <div class="dashWorkStationList">
        <div class="dashListTitle">
          工作站列表<i
            class="el-icon-refresh-left"
            @click="getWorkStationList()"
          ></i>
        </div>
        <el-button
          class="addWorkStationButton"
          type="primary"
          @click="showAddWorkStationDialog"
          >新增</el-button
        >
        <el-table
          :data="
            workStationData.filter(
              (data) =>
                !searchWorkStation ||
                data.name
                  .toLowerCase()
                  .includes(searchWorkStation.toLowerCase())
            )
          "
          style="width: 100%"
          height="92%"
          stripe
          :row-style="{ height: '60px' }"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="工作台名称:">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="工作台 ID:">
                  <span>{{ props.row.stationId }}</span>
                </el-form-item>
                <el-form-item label="工作台编号:">
                  <span>{{ props.row.shortId }}</span>
                </el-form-item>
                <el-form-item label="上次登录:">
                  <span>{{ props.row.loginTime }}</span>
                </el-form-item>
                <el-form-item label="创建时间:">
                  <span>{{ props.row.createTime }}</span>
                </el-form-item>
                <el-form-item label="accessKey:">
                  <div>{{ props.row.accessKey }}</div>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="上次登录" prop="loginTime"> </el-table-column>
          <el-table-column label="工作台名称" prop="name"> </el-table-column>
          <el-table-column label="工作台编号" prop="shortId"> </el-table-column>
          <el-table-column align="right">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="searchWorkStation"
                size="medium"
                placeholder="输入关键字搜索"
              />
            </template>

            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="workstationDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 新增工作站对话框 -->
    <el-dialog
      title="新增工作站"
      :visible.sync="canWorkStationDialogVisble"
      width="30%"
      @close="newWorkStationName = ''"
    >
      <el-input
        placeholder="请输入新工作站名称"
        v-model="newWorkStationName"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideAddWorkStationDialog">取 消</el-button>
        <el-button type="primary" @click="addWorkStation">新 增</el-button>
      </span>
    </el-dialog>
    <span class="copyRight">@CopyRight[2021]珠海市司迈科技有限公司 version:1.0.4</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminName: "",
      adminId: "",
      //仪表盘chats对象
      serverCharts: null,
      serverValue: 0,
      //工作站列表数据
      workStationData: [],
      searchWorkStation: "",
      //用户列表数据
      userData: [],
      searchUser: "",
      //房间列表数据
      roomData:[],
      //控制添加用户对话框的显示与隐藏
      canWorkStationDialogVisble: false,
      //添加工作站名称
      newWorkStationName: "",
      //控制新增管理员对话框的显示与隐藏
      canAdminDialogVisble: false,
    };
  },
  created() {
    this.adminName = window.localStorage.getItem("name");
    this.adminId = window.localStorage.getItem("adminId");
  },
  mounted() {
    this.initServerCharts(); //初始化服务器状态charts
    this.getWorkStationList(); //获取工作站列表
    this.getUserList(); //获取用户列表
    this.getServerInfo(); //获取服务器信息
    this.getRoomList();//获取房间信息
    //轮询更新数据
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getServerInfo(); //获取服务器信息
      }, 0);
    }, 3000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    //初始化服务器压力仪表盘对象
    initServerCharts() {
      this.serverCharts = this.$echarts.init(this.$refs.server);
    },
    //获取服务器压力
    async getServerInfo() {
      const { data: res } = await this.$http.get("/api/v1/admin/monitor/");
      this.serverValue = (
        100 *
        (res.data.jvmInfoDTO.free / res.data.jvmInfoDTO.total)
      ).toFixed(2);
      const option = {
        series: [
          {
            type: "gauge",
            radius:'70%',
            data: [
              {
                value: this.serverValue,
              },
            ],
          },
        ],
      };
      this.serverCharts.setOption(option);
    },
    //获取在线房间列表
    async getRoomList(){
      const { data: res } = await this.$http.get("/api/v1/room/online/list");
      this.roomData = res.data;
      console.log(res.data);
    },
    //获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.get("/api/v1/wx/user/online");
      this.userData = res.data;
    },
    //删除工作站按钮
    workstationDelete(index, row) {
      console.log(index, row);
      this.$confirm(
        "此操作将永久删除【" + row.name + "】工作站,是否继续?",
        "重要提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          console.log(row.stationId);
          const { data: res } = await this.$http.post(
            "/api/v1/admin/station/del/" + row.stationId
          );
          console.log(res);
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.getWorkStationList();
          }
        })
        .catch(() => {
          this.$message.info("已取消操作");
        });
    },
    //获取工作站列表
    async getWorkStationList() {
      const { data: res } = await this.$http.get("/api/v1/admin/station/list?"+"pageSize=1000");
      this.workStationData = res.data.records;
      console.log(res)
      console.log('lll')
    },
    //新增工作站
    async addWorkStation() {
      const { data: res } = await this.$http.post(
        "/api/v1/admin/station/add/" + this.newWorkStationName
      );
      if (res.code == 200) {
        this.$message.success("添加成功！");
        this.canWorkStationDialogVisble = false;
        this.getWorkStationList();
      } else {
        this.$message.error("添加失败,请与技术人员联系");
        return;
      }
    },
    //展示添加工作站对话框
    showAddWorkStationDialog() {
      this.canWorkStationDialogVisble = true;
    },
    //关闭添加工作站对话框
    hideAddWorkStationDialog() {
      this.canWorkStationDialogVisble = false;
    },
    //修改密码
    editPassword(){
      console.log("修改密码");
      this.$prompt('请输入新的密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/,
          inputErrorMessage: '密码长度必须在6-15位,由数字和字母组成',
          closeOnClickModal: false,
          inputPlaceholder: '您的新密码'
        }).then(({ value }) => {
          this.$confirm('确认要将【'+this.adminName+'】的密码修改为：'+value, '重要提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const {data: res} = await this.$http.put("/api/v1/admin/user/changePwd", {
            'id': this.adminId,
            'username': this.adminName,
            'pwd': value
          });
          if(res.code == 200){
            this.$message.success('密码修改成功！');
          }else{
            this.$message.error('密码修改失败');
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });       
        });
    }
  },
};
</script>

<style>
.dashBackground {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}
.dashTopBox {
  height: 5%;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 5px #888888;
  overflow: hidden;
  position: relative;
}
.dashTitle {
  height: 100%;
  width: 255px;
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
}
.dashTitle1 {
  color: #e26a13;
}
.dashTitle2 {
  color: #148ce1;
}
.adminInfoBox {
  background-color: #e26a13;
  height: 100%;
  width: 120px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminInfo {
  font-size: 18px;
  color: #eee;
  cursor: pointer;
}
.el-dropdown-menu {
  width: 100px;
}
.dashListTitle {
  height: 5%;
  color: #363636;
  margin-top: 5px;
  margin-left: 20px;
  font-size: 22px;
  font-weight: bold;
}
.dashListTitle i {
  cursor: pointer;
  color: #4f4f4f;
  margin-left: 10px;
}
.dashListTitle i:hover {
  color: #9c9c9c;
}
/* 房间列表区域 */
.dashStatsBox {
  height: 90%;
  width: 40%;
  margin-top: 20px;
  margin-left: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #828282;
  float: left;
}

.roomListBox {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.roomToast{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,50%);
  color: #828282;
}
.roomListBanner{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.el-carousel__item:nth-child(2n) {
  background-color: #ef8b43;
  border-radius: 20px;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #ef8b43;
  border-radius: 20px;
}
.roomInfoBox{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #eee;
  font-weight: bold;
}
.roomIcon{
  font-size: 36px;
}
.roomText{
  margin-top: 5px;
}
.roomNumber{
  color: #ffffcc;
}
.dashStats {
  height: 45%;
  width: 100%;
}
.statsBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.stats {
  height: 60%;
  width: 30%;
  background-color: #fff;
  box-shadow: 2px 2px 5px #828282 inset;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.stats:hover {
  box-shadow: none;
}
.statsTitle {
  font-size: 28px;
  font-weight: bold;
}
.statsNumber {
  font-size: 56px;
  font-weight: bold;
  color: #14e169;
}
.dashWorkStationBox {
  width: 56%;
  height: 39%;
  float: left;
  margin-top: 10px;
  margin-left: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #828282;
}
.dashWorkStationBox .dashListTitle{
  height: 10%;
}
.dashWorkStationBox .dashWorkStationList {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.addWorkStationButton {
  position: absolute;
  z-index: 99;
  right: 20px;
  top: 5px;
}
/* 服务器状态区域 */
.dashServerBox {
  width: 25%;
  height: 50%;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #828282;
}
.dashServer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.dashServerCharts {
  height: 95%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 用户列表区域 */
.dashUserBox {
  width: 30%;
  height: 50%;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #828282;
}
.dashUserBox .dashListTitle{
  height: 10%;
}
.dashUserBox .dashUserList {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>