<template>
  <div class="background">
    <div class="loginBox">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/icon/标志.jpg" id="login-logo"/>
      </div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="rules"
        label-width="0px"
        class="login_form"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <!-- 验证码区域 -->
        <div class="confirmTestBox">
          <el-form-item prop="code" class="confirmTest">
            <el-input
              v-model="loginForm.code"
              prefix-icon="el-icon-help"
              @keyup.enter.native.prevent="login()"
            ></el-input>
          </el-form-item>
          <div class="confirmPicBox" @click="getConfirmPic()">
            <img class="confirmPic" :src="imgStr" :onerror="defaultImg">
          </div>
        </div>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
      <span class="switchUserLogin" @click="goToLogin()"><i class="el-icon-sort"></i>工作站登录</span>
    </div>
    <span class="copyRight">@CopyRight[2021]珠海市司迈科技有限公司 version:1.0.4</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //验证码
      imgStr: "../assets/icon/confirmPic.jpg",
      //这是登录表单的数据绑定对象
      loginForm: {
        code: "",
        username: "",
        password: "",
        uuid: "",
      },
      //这是表单本地验证规则对象
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 8, message: "长度在3到8个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 15, message: "长度在6到15个字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    defaultImg() {
      return 'this.src="' + require("../assets/icon/confirmPic.jpg") + '"';
    },
  },
  async mounted(){
    //获取验证码
    this.getConfirmPic();
  },
  methods: {
    //切换到工作站登录
    goToLogin(){
        this.$router.push("/login");
    },
    //获取验证码
    async getConfirmPic() {
      const { data: res } = await this.$http.get("/api/v1/admin/captchaImage");
      this.imgStr = "data:image/jpg;base64," + res.img;
      this.loginForm.uuid = res.uuid;
    },
    //点击重置按钮重置登录表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    //登录前预验证(服务器)
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post("/api/v1/admin/user/login", this.loginForm);
        console.log(res);
        if (res.code != 200){
           this.getConfirmPic();
           this.loginForm.code = '';
           return this.$message.error("登录失败！"+res.msg);  
        }
        this.$message.success("登录成功！"+"欢迎您【"+this.loginForm.username+"】");
        console.log('这里')
        console.log(res);
        window.sessionStorage.setItem("adminToken", res.data.token);
        // window.sessionStorage.setItem("adminToken", "Bearer "+res.data);
        window.localStorage.setItem("name", res.data.administratorInfo.userName);
        window.localStorage.setItem("adminId", res.data.administratorInfo.id);
        this.$router.replace("/dashboard");
      });
    },
  },
};
</script>

<style>
.background {
  height: 100%;
  width: 100%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: adminLoginApear 0.5s forwards;
}
@keyframes adminLoginApear{
  from{background-color: #363636;}
  to{background-color: #eee;}
}
.loginBox {
  width: 550px;
  height: 400px;
  background-color: #363636;
  position: relative;
  border-radius: 10px;
  animation: loginBoxApear 0.5s forwards;
}
@keyframes loginBoxApear{
  from{opacity: 0;}
  to{opacity: 1;}
}
.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #f29f63;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

#login-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.login_form {
  padding: 0 40px;
  margin-bottom: 0;
  margin-top: 100px;
}
.btns {
  float: right;
  margin: 0 auto;
}
.switchUserLogin{
  text-decoration-line: underline;
  color: #828282;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translate(-50%, -50%);
}
.confirmTestBox {
  display: flex;
  flex-wrap: nowrap;
}

.confirmTest {
  width: 50%;
}

.confirmPicBox {
  height: 40px;
  width: 100px;
  margin-left: 10px;
}

.confirmPic {
  border: 0.5px solid #e26a13;
  border-radius: 3px;
  height: 100%;
  width: 100%;
}
</style>