import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/login.vue'
import Admin_login from '../components/admin_login.vue'
import Patient_video from '../components/patient_video.vue'
import Dashboard from '../components/dashboard.vue'

Vue.use(Router)
Vue.prototype.$echarts = window.echarts

const router = new Router({
  routes: [
    {path: '/', redirect: '/login'},
    {path: '/login', component: Login},
    {path: '/admin_login', component: Admin_login},
    {path: '/patient_video', component:Patient_video},
    {path: '/dashboard', component:Dashboard},
  ]
})

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if(to.path == '/login'||to.path == '/admin_login') return next();
  //获取管理员token
  const adminTokenStr = window.sessionStorage.getItem('adminToken');
  //获取工作站token
  const tokenStr = window.sessionStorage.getItem('token');
  if(to.path == '/dashboard'&&!adminTokenStr) return next('/admin_login');
  if(to.path == '/patient_video'&&!tokenStr) return next('/login');
  // if(!tokenStr) return next('/login');
  next();
})

export default router
