import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'

import axios from 'axios'
// import TRTC from 'trtc-js-sdk'
// import {LibGenerateTestUserSig} from '../psrivate/lib-generate-test-usersig.min'

//配置请求的根路径
axios.defaults.baseURL = 'https://yhxcx.scanmed-cn.com/'

// axios.defaults.baseURL = 'http://159.75.200.5:8091/'
// axios.defaults.baseURL = 'https://www.seelittle.com:9999/'
//每次发送接口请求进行token验证
axios.interceptors.request.use(config => {
  const token = window.sessionStorage.getItem('token')
  
  token ? config.headers.Authorization = token : null;
  return config
});
//每次接口请求携带token值传给后台
// axios.interceptors.response.use(res => {
//   // if (res.data.res_code === 401) {
//   //   router.replace('/login');
//   //   window.sessionStorage.removeItem('token')
//   // }
//   return res
// })
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
